export default [
  {
    image: 'http://www.brunico.com/gimages/delegatephotos/large/815981.jpg',
    name: 'Xavier Debane',
    title: 'Head of Innovation & Agile',
    company: 'Manulife',
  },
  {
    image: 'http://www.brunico.com/gimages/delegatephotos/large/763910.jpg',
    name: 'Aly Dhalla',
    title: 'CEO and Co-Founder',
    company: 'Finaeo Inc.',
  },
  {
    image: 'http://www.brunico.com/gimages/delegatephotos/large/815978.jpg',
    name: 'Breanna Hughes',
    title: 'AVP Product',
    company: 'League Inc.',
  },
  {
    image: 'http://www.brunico.com/gimages/delegatephotos/large/726430.jpg',
    name: 'Christian Lassonde',
    title: 'Founder & Managing Partner',
    company: 'Impression Ventures',
  },
  {
    image: 'http://www.brunico.com/gimages/delegatephotos/large/816105.jpg',
    name: 'Ian MacDonald',
    title: 'AVP, CXO Data and Analytics',
    company: 'Sun Life Financial',
  },
  {
    image: 'http://www.brunico.com/gimages/delegatephotos/large/816104.jpg',
    name: 'Laura McKay',
    title: 'COO & Co-Founder',
    company: 'PolicyMe',
  },
]
