import React from 'react'
import styled from '@emotion/styled'
import Fade from 'react-reveal/Fade'
import ExpertiseSection from '../About/ExpertiseSection'
import AnimatedQuotes from '../Shared/Quotes/Animated'
import LogosBanner from '../Shared/Banners/Logos'
import StatsBanner from '../Shared/Banners/Stats'
import HeroSection from '../About/HeroSection'
import CixAccordion from './CixAccordion'
import { GradientButton, CTA, CTAText } from '../Buttons'
import { InnerContainer, SplitSection } from '../About/AboutUsComponents'

import panel1 from '../../images/cix/panel1.jpg'
import panel2 from '../../images/cix/panel2.jpg'
import panel3 from '../../images/cix/panel3.jpg'
import panel4 from '../../images/cix/panel4.jpg'
import panel5 from '../../images/cix/panel5.jpg'
import panel6 from '../../images/cix/panel6.jpg'

import panelPeopleData from './panelPeopleData'

const panelImages = [panel1, panel2, panel3, panel4, panel5, panel6]

const CTAButton = CTA.withComponent('a')

const Section = styled('div')`
  padding: 100px 10%;
  background: ${props => (props.background ? props.background : 'white')};
  @media (max-width: ${props => props.theme.screenWidth.large}) {
    padding: 100px 10%;
  }
  &.what-we-do {
    display: flex;
    flex-direction: row;
    @media (max-width: ${props => props.theme.screenWidth.large}) {
      flex-direction: column;
    }
  }
  @media (max-width: ${props => props.theme.screenWidth.large}) {
    padding: 80px 10%;
  }
`

const SectionTitle = styled('h2')`
  color: ${props => (props.color ? props.color : 'black')};
  font-family: ${props => props.theme.fonts.primaryMedium};
  font-size: 50px;
  font-weight: 500;
  line-height: 65px;
  text-align: ${props => (props.center ? 'center' : 'left')};
  margin: 0 0 16px;
  @media (max-width: ${props => props.theme.screenWidth.small}) {
    font-size: 34px;
    line-height: 1.18;
  }
  &.client {
    margin-top: 37px;
    @media (max-width: ${props => props.theme.screenWidth.large}) {
      margin-top: 0;
    }
  }
`
const SectionSubTitle = styled('h3')`
  color: ${props => (props.color ? props.color : 'black')};
  font-family: ${props => props.theme.fonts.secondary};
  font-size: 38px;
  font-weight: 200;
  line-height: 42px;
  text-align: ${props => (props.center ? 'center' : 'left')};
  margin-bottom: 40px;
  @media (max-width: ${props => props.theme.screenWidth.large}) {
    margin: 0;
  }
  @media (max-width: ${props => props.theme.screenWidth.small}) {
    font-size: 22px;
    line-height: 1;
    margin-bottom: 30px;
  }
`
const CIXJoinUs = styled('div')`
  display: flex;
  @media (max-width: ${props => props.theme.screenWidth.large}) {
    flex-direction: column-reverse;
  }
`
const CIXVideo = styled('iframe')`
  width: 100%;
  @media (max-width: ${props => props.theme.screenWidth.large}) {
    height: 432px;
    margin-top: 60px;
  }
  @media (max-width: ${props => props.theme.screenWidth.small}) {
    height: 255px;
    margin-top: 30px;
  }
`

const RightContainer = styled('div')`
  width: 50%;
  margin-left: 104px;
  p {
    font-family: ${props => props.theme.fonts.sourceCode};
    text-transform: uppercase;
    font-size: 16px;
    letter-spacing: 1px;
    margin: 0 0 8px;
  }
  @media (max-width: ${props => props.theme.screenWidth.large}) {
    margin-left: 0;
    width: 100%;
  }
  ${SectionSubTitle} {
    width: 100%;
    @media (max-width: ${props => props.theme.screenWidth.large}) {
      margin: 0 0 40px;
    }
    @media (max-width: ${props => props.theme.screenWidth.small}) {
      line-height: 1.4;
      font-size: 20px;
      margin: 0 0 30px;
    }
  }
`
const PanelPeople = styled('div')`
  display: flex;
  margin-top: 80px;
  @media (max-width: ${props => props.theme.screenWidth.large}) {
    margin-top: 60px;
    flex-wrap: wrap;
  }
`
const ButtonWrapper = styled('div')`
  text-align: center;
  @media (max-width: ${props => props.theme.screenWidth.large}) {
    margin-top: 40px;
  }
  @media (max-width: ${props => props.theme.screenWidth.small}) {
    margin-top: 0;
  }
`
const PanelPerson = styled('div')`
  width: 20%;
  margin-right: 45px;
  @media (max-width: ${props => props.theme.screenWidth.large}) {
    width: 26%;
  }
  @media (max-width: ${props => props.theme.screenWidth.small}) {
    width: 44%;
    margin: 0 3%;
  }

  img {
    width: 100%;
  }
  p {
    font-family: ${props => props.theme.fonts.secondary};
    font-size: 16px;
    margin: 0;
  }
  p:nth-of-type(1) {
    font-weight: bold;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
  p:nth-of-type(3) {
    color: #132b3e;
    @media (max-width: ${props => props.theme.screenWidth.small}) {
      margin: 0 0 30px;
    }
  }
`

import lightGradientBackground from '../../images/light-gradient-background.png'

export default function Cix(props) {
  const {
    ctaData,
    aboutUsData: {
      statsSectionTitle,
      statsSectionList,
      whatMattersSectionQuotes,
    },
    cixData: { heroBackgroundImage, expertiesSectionImages },
  } = props

  return (
    <React.Fragment>
      <HeroSection
        heroTitle={'Your Digital Product Innovation Partner'}
        heroBody={''}
        heroBackgroundImage={heroBackgroundImage}
        isCix
      />

      <Section>
        <SectionTitle center style={{ flex: 1 }}>
          Proud Sponsor of CIX Summit 2019
        </SectionTitle>
        <SectionSubTitle center style={{ flex: 1 }}>
          Like our name suggests, Crowdlinker was born from a desire to
          completely transform the way people connect with one another,
          leveraging innovative technologies and top-tier talent to create
          amazing digital products. From our own humble beginnings as a
          product-focused startup, we relate with companies who have that big
          idea and are now looking to scale it into something even bigger.
        </SectionSubTitle>
        <ButtonWrapper>
          <CTAButton
            href="#join-us-section"
            whiteBtn={true}
            gradientLeft={'1px'}
            gradientTop={'1px'}
            gradientHeight="calc(100% - 2px)"
            gradientWidth="calc(100% - 2px)"
            fontWeight={'500'}
            fontFamily={'primaryMedium'}
            padding={'0 22px'}
          >
            <CTAText>Check out our talk</CTAText>
          </CTAButton>
        </ButtonWrapper>
      </Section>
      <Section background="black" className="what-we-do">
        <SectionTitle color="white" style={{ flex: 1 }}>
          What we do
        </SectionTitle>
        <SectionSubTitle color="white" style={{ flex: 1 }}>
          We partner with forward-thinking businesses to launch new products,
          improve existing technology, and advise on product strategy.
        </SectionSubTitle>
      </Section>

      <CixAccordion />

      <Section>
        <InnerContainer>
          <SplitSection width="40%">
            <Fade delay={400}>
              <SectionTitle className="client">
                Your Success Partners
              </SectionTitle>
              <SectionSubTitle>
                Through our experience, we have developed award-winning
                practices and built out a robust team of industry-first
                strategists, designers, developers, marketing professionals, and
                more. In 2015, we decided to expand our company vision, becoming
                a one-of-a-kind digital product studio built to design and
                develop people-first solutions alongside meaningful business
                objectives.
              </SectionSubTitle>
            </Fade>
          </SplitSection>
          <SplitSection
            width="60%"
            padding="0 0 0 7%"
            style={{ maxHeight: '533px' }}
          >
            <AnimatedQuotes quotes={whatMattersSectionQuotes} />
          </SplitSection>
        </InnerContainer>
      </Section>

      <Section id="join-us-section">
        <CIXJoinUs>
          <CIXVideo
            src="https://www.youtube.com/embed/iU1sBGM9EaU"
            frameBorder="0"
            allowFullScreen
          />
          <RightContainer style={{ width: '100%' }}>
            <p>Wednesday, October 16 at 4pm</p>
            <SectionTitle>Future of Insurtech</SectionTitle>
            <SectionSubTitle className="article">
              Join us to learn more about how forward-thinking insurance leaders
              are staying on track with their digital transformation, what kinds
              of emerging technologies have got them excited, and how they are
              partnering with startups to become more innovative.
            </SectionSubTitle>
            <GradientButton
              linkTo="/contact"
              whiteBtn={true}
              gradientLeft={'1px'}
              gradientTop={'1px'}
              gradientHeight="calc(100% - 2px)"
              gradientWidth="calc(100% - 2px)"
              fontWeight={'500'}
              fontFamily={'primaryMedium'}
              ctaText={'Schedule a meeting'}
              padding={'0 20px'}
            />
          </RightContainer>
        </CIXJoinUs>
        <PanelPeople>
          {panelPeopleData.map((person, i) => (
            <PanelPerson key={i}>
              <img src={panelImages[i]} />
              <p>{person.name}</p>
              <p>{person.title}</p>
              <p>{person.company}</p>
            </PanelPerson>
          ))}
        </PanelPeople>
      </Section>
      <LogosBanner />
      <ExpertiseSection
        expertiseSectionTitle={"How we're different"}
        expertiseSectionBody={{
          expertiseSectionBody:
            'We focus on end-to-end product development, from research & strategy, to ideation & design, engineering, and product marketing. We work collaboratively in cross-functional teams, bringing the expertise of each team member to every project. All our work is done in-house.',
        }}
        expertiesSectionImages={expertiesSectionImages}
        cixBackground={lightGradientBackground}
      />
      <StatsBanner
        statsSectionTitle={statsSectionTitle}
        statsSectionList={statsSectionList}
      />
    </React.Fragment>
  )
}
