import React, { useState } from 'react'
import styled from '@emotion/styled'
import Collapse from '@kunukn/react-collapse'
import '../../../styles/react-collapse.css'

import pagesData from './pagesData'
import case0 from '../../../images/cix/case0.jpg'
import case1 from '../../../images/cix/case1.jpg'
import case2 from '../../../images/cix/case2.jpg'
import case3 from '../../../images/cix/case3.jpg'

const caseImages = [case0, case1, case2, case3]

const AccordionPage = styled('div')`
  background: ${props => props.background};
  padding: 0 10%;
  color: white;
  font-family: ${props => props.theme.fonts.secondary};
  @media (max-width: ${props => props.theme.screenWidth.small}) {
    padding: 0 10%;
  }
`

const AccordionNav = styled('div')`
  font-size: ${props => (props.isOpen ? '60px' : '40px')};
  padding-top: ${props => (props.isOpen ? '70px' : '0')};
  height: 89px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.2s;
  transition-delay: 0.1s;
  cursor: pointer;
  @media (max-width: ${props => props.theme.screenWidth.small}) {
    font-size: ${props => (props.isOpen ? '40px' : '30px')};
    padding-top: ${props => (props.isOpen ? '60px' : '0')};
    line-height: 0.8;
    span {
      margin-right: 20px;
    }
  }
`

const Header2 = styled('h4')`
  font-size: 40px;
  font-weight: 300;
  line-height: 45px;
  @media (max-width: ${props => props.theme.screenWidth.small}) {
    font-size: 24px;
    line-height: normal;
  }
`

const Paragraph1 = styled('p')`
  font-size: 38px;
  font-weight: 300;
  line-height: 42px;
  @media (max-width: ${props => props.theme.screenWidth.small}) {
    font-size: 22px;
    line-height: normal;
    margin-top: 40px;
  }
`
const Paragraph2 = styled('p')`
  font-size: 28px;
  font-weight: 300;
  line-height: 34px;
  @media (max-width: ${props => props.theme.screenWidth.large}) {
    margin-top: 4px;
  }
  @media (max-width: ${props => props.theme.screenWidth.small}) {
    font-size: 18px;
    line-height: normal;
  }
`

const BottomSection = styled('div')`
  display: flex;
  margin-bottom: 80px;
  img {
    width: 40%;
    margin-left: 5%;
    object-fit: contain;
    object-position: top;
    margin-top: 20px;
  }
  @media (max-width: ${props => props.theme.screenWidth.large}) {
    flex-direction: column-reverse;
    img {
      margin: 0;
      width: 100%;
      margin-bottom: 40px;
    }
  }
  @media (max-width: ${props => props.theme.screenWidth.small}) {
    flex-direction: column;
  }
`
const DropdownArrow = styled('div')`
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 15px 15px 15px;
  border-color: transparent transparent #ffffff transparent;
  margin-top: 10px;
  transform: ${props => (props.isOpen ? 'rotate(180deg)' : 'rotate(0)')};
  @media (max-width: ${props => props.theme.screenWidth.small}) {
    border-width: 0 11px 11px 11px;
    margin-top: 4px;
  }
`

export default function index() {
  const [activeIndex, setActiveIndex] = useState(0)

  const onToggle = index => {
    if (activeIndex === index) {
      setActiveIndex(null)
    } else {
      setActiveIndex(index)
    }
  }

  return (
    <div id="accordion-section">
      {pagesData.map((page, i) => (
        <AccordionPage background={page.background} key={i}>
          <AccordionNav onClick={() => onToggle(i)} isOpen={activeIndex === i}>
            <span>{page.navTitle}</span>{' '}
            <DropdownArrow isOpen={activeIndex === i} />
          </AccordionNav>
          <Collapse
            className="collapse-css-transition"
            isOpen={activeIndex === i}
          >
            <Paragraph1>{page.paragraph1}</Paragraph1>
            <BottomSection>
              <div>
                <Header2>{page.header2}</Header2>
                <Paragraph2>{page.paragraph2}</Paragraph2>
              </div>
              <img src={caseImages[i]} />
            </BottomSection>
          </Collapse>
        </AccordionPage>
      ))}
    </div>
  )
}
