import React from 'react'
import styled from '@emotion/styled'
import Fade from 'react-reveal/Fade'
import BackgroundImage from 'gatsby-background-image'
import useWindowSize from '../../../util/useWindowSize'

import { GradientButton } from '../../Buttons'

const HeroContainer = styled(BackgroundImage)`
  position: relative;
  height: 774px;
  width: 100%;
  padding: 111px 10%;
  color: white;
  display: flex;
  align-items: flex-end;
  margin-top: 82px;
  @media (max-width: 1080px) {
    margin-top: 100px;
  }
  @media (max-width: ${props => props.theme.screenWidth.large}) {
    padding: 52px 74px;
    padding: ${props => (props.isCix ? '70px 74px' : '52px 74px')};
  }
  @media (max-width: ${props => props.theme.screenWidth.small}) {
    height: auto;
    margin-top: 62px;
    padding: 66px 10%;
    align-items: flex-start;
    &::before {
      background-position-y: top !important;
    }
    &::after {
      background-position-y: top !important;
    }
  }
  @media (max-width: ${props => props.theme.screenWidth.xsmall}) {
    height: 678px;
  }
`

const HeroText = styled('div')`
  width: ${props => (props.isCix ? '60%' : '50%')};
  margin-top: 309px;
  @media (max-width: ${props => props.theme.screenWidth.large}) {
    width: 80%;
  }
  @media (max-width: ${props => props.theme.screenWidth.small}) {
    width: 100%;
    margin-top: ${props => (props.isCix ? '238px' : '296px')};
  }
  h1 {
    font-family: ${props => props.theme.fonts.primaryMedium};
    font-weight: 500;
    font-size: 60px;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
    line-height: 1.2;
    margin-bottom: 23px;
    @media (max-width: ${props => props.theme.screenWidth.small}) {
      font-size: 29px;
      margin-bottom: 21px;
    }
  }

  h3 {
    font-family: ${props => props.theme.fonts.secondary};
    font-weight: 300;
    font-size: 38px;
    line-height: 1.1;
    @media (max-width: ${props => props.theme.screenWidth.large}) {
      font-size: 36px;
    }
    @media (max-width: ${props => props.theme.screenWidth.small}) {
      font-size: 24px;
    }
  }
`

const Overlay = styled('div')`
  display: none;
  @media (max-width: ${props => props.theme.screenWidth.small}) {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    width: 100%;
    height: ${props => (props.isCix ? '293px' : '358px')};
    background: linear-gradient(
      13deg,
      rgba(0, 0, 0, 1) 10%,
      rgba(0, 0, 0, 0.4) 23%,
      rgba(0, 0, 0, 0) 36%
    );
  }
`

export default function HeroSection({
  heroTitle,
  heroBody,
  heroBackgroundImage,
  isCix,
}) {
  const screenSize = useWindowSize()
  return (
    <HeroContainer
      isCix={isCix}
      sizes={heroBackgroundImage.sizes}
      style={
        screenSize === 'mobile'
          ? {
              backgroundPosition: 'top center',
              backgroundSize: '650px',
              backgroundRepeat: 'no-repeat',
            }
          : {}
      }
    >
      <Overlay isCix={isCix} />
      <Fade bottom distance={'20px'}>
        <HeroText isCix={isCix}>
          <h1>{heroTitle}</h1>

          {isCix ? (
            <GradientButton
              linkTo="/contact"
              whiteBtn={true}
              gradientLeft={'1px'}
              gradientTop={'1px'}
              gradientHeight="calc(100% - 2px)"
              gradientWidth="calc(100% - 2px)"
              fontWeight={'500'}
              fontFamily={'primaryMedium'}
              padding={'0 22px'}
            />
          ) : (
            <h3>{heroBody}</h3>
          )}
        </HeroText>
      </Fade>
    </HeroContainer>
  )
}
