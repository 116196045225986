import styled from '@emotion/styled'

export const Section = styled('div')`
  padding: 160px 10%;
  background: ${props => (props.background ? props.background : 'white')};
  background-image: ${props =>
    props.backgroundImage ? `url(${props.backgroundImage})` : null};
  background-size: cover;
  background-repeat: no-repeat;
  @media (max-width: ${props => props.theme.screenWidth.large}) {
    padding: 117px 74px;
  }
  @media (max-width: ${props => props.theme.screenWidth.large}) {
    padding: 60px 10%;
  }
`

export const InnerContainer = styled('div')`
  position: relative;
  display: flex;
  margin-top: ${props => (props.marginTop ? props.marginTop : '0')};
  @media (max-width: ${props => props.theme.screenWidth.large}) {
    flex-direction: ${props =>
      props.mobileReverse ? 'column-reverse' : 'column'};
    margin-top: ${props => (props.marginTop ? '46px' : 0)};
  }
`

export const SplitSection = styled('div')`
  width: ${props => (props.width ? props.width : '50%')};
  padding: ${props => (props.padding ? props.padding : '0')};
  @media (max-width: ${props => props.theme.screenWidth.large}) {
    width: 100%;
    padding: 0;
  }
`

export const SectionTitle = styled('h2')`
  color: ${props => (props.color ? props.color : props.theme.colors.magenta)};
  font-family: ${props => props.theme.fonts.primaryMedium};
  font-size: 60px;
  font-weight: 500;
  line-height: 1.13;
  text-align: ${props => (props.center ? 'center' : 'left')};
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : '12px')};
  margin-top: ${props => (props.marginTop ? props.marginTop : '0')};
  @media (max-width: ${props => props.theme.screenWidth.large}) {
    text-align: ${props =>
      props.center || props.mobileCenter ? 'center' : 'left'};
    font-size: 50px;
  }
  @media (max-width: ${props => props.theme.screenWidth.small}) {
    font-size: 34px;
    text-align: ${props => (props.mobileLeft ? 'left' : null)};
  }
`

export const SectionSubTitle = styled('h3')`
  color: ${props => (props.color ? props.color : 'black')};
  font-family: ${props => props.theme.fonts.secondary};
  font-size: ${props => (props.fontSize ? props.fontSize : '36px')};
  font-weight: 300;
  line-height: ${props => (props.lineHeight ? props.lineHeight : '1.17')};
  width: ${props => (props.width ? props.width : '56%')};
  padding: ${props => (props.padding ? props.padding : '0')};
  margin: ${props => (props.center ? '0 auto' : '0')};
  text-align: ${props => (props.center ? 'center' : 'left')};
  @media (max-width: ${props => props.theme.screenWidth.large}) {
    font-size: 26px;
    width: 80%;
  }
  @media (max-width: ${props => props.theme.screenWidth.small}) {
    font-size: 20px;
    text-align: left;
    width: 100%;
    line-height: 1.4;
  }
`
export const SectionCategoryText = styled('p')`
  font-family: ${props => props.theme.fonts.sourceCode};
  font-size: 18px;
  letter-spacing: 0.8px;
  margin: 7px 0;
  text-transform: uppercase;
  @media (max-width: ${props => props.theme.screenWidth.large}) {
    text-align: center;
  }
  @media (max-width: ${props => props.theme.screenWidth.small}) {
    text-align: left;
  }
`

export const SectionText = styled('p')`
  color: black;
  white-space: pre-line;
  font-family: ${props => props.theme.fonts.secondary};
  font-size: 23px;
  line-height: 1.4;
  margin: 7px 0 50px;
  @media (max-width: ${props => props.theme.screenWidth.large}) {
    font-size: 21px;
    text-align: ${props => (props.storySection ? 'left' : 'center')};
    width: ${props => (props.storySection ? '100%' : '70%')};
    margin: ${props => (props.storySection ? '7px 0 50px' : '7px auto 45px;')};
  }
  @media (max-width: ${props => props.theme.screenWidth.small}) {
    font-size: ${props => (props.storySection ? '20px' : '17px')};
    text-align: left;
    margin: 6px 0 28px;
    width: 100%;
  }
`

export const CtaWrapper = styled('div')`
  @media (max-width: ${props => props.theme.screenWidth.large}) {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
  }
`
