import { useState, useEffect } from 'react'
import { theme } from '../styles/theme'

function getWindowSize() {
  const { small, large } = theme.screenWidth
  const windowGlobal = typeof window !== 'undefined' && window

  //Removing 'px' from the end of the theme screenWidth variables and turning it into a number
  const mobileWidth = Number(small.slice(0, -2))
  const tabletWidth = Number(large.slice(0, -2))

  let screenSize
  if (windowGlobal.innerWidth <= mobileWidth) {
    screenSize = 'mobile'
  } else if (windowGlobal.innerWidth <= tabletWidth) {
    screenSize = 'tablet'
  } else {
    screenSize = 'desktop'
  }
  return screenSize
}

export default function useWindowSize() {
  const [windowSize, setWindowSize] = useState() //Cant set initial state here, see comment below.

  useEffect(() => {
    function handleResize() {
      setWindowSize(getWindowSize())
    }
    // Initialize window size after load because window object doesn't exist in SSR.
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowSize
}
