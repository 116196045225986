import React from 'react'
import Layout from '../../components/layout'
import StickyNav from '../../components/Navigation/StickyNav'
import { Helmet } from 'react-helmet'
import Cix from '../../components/Cix/index'
import { graphql } from 'gatsby'

const metaTitle = 'CIX Summit 2019 - Silver Sponsors'

const metaDescription =
  'Like our name suggests, Crowdlinker was born from a desire to completely transform the way people connect with one another, leveraging innovative technologies and top-tier talent to create amazing digital products. From our own humble beginnings as a product-focused startup, we relate with companies who have that big idea and are now looking to scale it into something even bigger.'

const CixPage = props => {
  const aboutUsData = props.data.contentfulAboutUs
  const ctaData = props.data.contentfulBlogHomepage
  const cixData = props.data.contentfulCixPage

  return (
    <Layout footerCTA={true} isCix>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta property="og:title" content={metaTitle} />
        <meta property="og:description" content={metaDescription} />
        <meta name="twitter:title" content={metaTitle} />
        <meta name="twitter:description" content={metaDescription} />
        <meta name="robots" content="noindex" />
      </Helmet>
      <StickyNav isCix />
      <Cix aboutUsData={aboutUsData} ctaData={ctaData} cixData={cixData} />
    </Layout>
  )
}

export default CixPage

export const pageQuery = graphql`
  query {
    contentfulAboutUs {
      statsSectionTitle
      statsSectionList {
        number
        text
      }
      whatMattersSectionQuotes {
        quote
        person
        title
        logo {
          title
          file {
            url
          }
        }
      }
    }
    contentfulBlogHomepage {
      ctaTitle
      ctaDescription
      ctaPlaceholder
      ctaButtonText
      ctaSuccessHeader
      ctaSuccessBody
    }
    contentfulCixPage {
      heroBackgroundImage {
        title
        sizes(maxWidth: 1000) {
          ...GatsbyContentfulSizes
        }
      }
      expertiesSectionImages {
        title
        sizes(maxWidth: 1000) {
          ...GatsbyContentfulSizes
        }
      }
    }
  }
`
