import React from 'react'
import styled from '@emotion/styled'
import { useInView } from 'react-intersection-observer'
import { keyframes } from '@emotion/core'
import Img from 'gatsby-image'
import {
  Section,
  InnerContainer,
  SplitSection,
  SectionTitle,
  SectionSubTitle,
  CtaWrapper,
} from '../AboutUsComponents'
import { GradientButton } from '../../Buttons'
import TransparentCTA from '../../Buttons/TransparentCTA'

const fadeUp = keyframes`
  from { 
    opacity: 0;
    transform: translateY(35px);
  }
  to { 
    opacity: 1; 
    transform: translateY(0);
  }
`
const ExpertiseImagesContainer = styled('div')`
  display: ${props => (props.showAnimation ? 'block' : 'none')};
  position: relative;
  height: 600px;
  max-width: 604px;
  margin: auto;
  @media (max-width: ${props => props.theme.screenWidth.small}) {
    height: 405px;
    max-width: 356px;
    margin: -30px auto 0;
  }
`
const GatsbyImg = styled(Img)`
  display: block;
  object-fit: cover;
  max-width: 500px;
  position: absolute !important;
  &.image0 {
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 78%;
    opacity: 0;
    animation: ${fadeUp} 1.8s ease;
    animation-fill-mode: forwards;
    @media (max-width: ${props => props.theme.screenWidth.large}) {
      width: 406px;
      height: 254px;
      top: 40%;
      margin: 0;
      left: auto;
      right: 5%;
      animation-delay: 1s;
      z-index: 3;
    }
    @media (max-width: ${props => props.theme.screenWidth.small}) {
      top: 42%;
      width: 71%;
      right: 0;
      height: auto;
    }
  }
  &.image1 {
    top: 52%;
    width: 50%;
    opacity: 0;
    animation: ${fadeUp} 1.8s ease;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
    @media (max-width: ${props => props.theme.screenWidth.large}) {
      width: 315px;
      height: 207px;
      top: 14%;
      z-index: 2;
    }
    @media (max-width: ${props => props.theme.screenWidth.small}) {
      top: 21%;
      width: 60%;
      height: auto;
    }
  }
  &.image2 {
    top: 61%;
    right: 3%;
    width: 40%;
    opacity: 0;
    animation: ${fadeUp} 1.8s ease;
    animation-delay: 1s;
    animation-fill-mode: forwards;
    @media (max-width: ${props => props.theme.screenWidth.large}) {
      width: 255px;
      height: 191px;
      top: 0;
      right: 11%;
      animation-delay: 0s;
      z-index: 1;
    }
    @media (max-width: ${props => props.theme.screenWidth.small}) {
      top: 0;
      right: 0;
      width: 58%;
      height: auto;
    }
  }
`

export default function ExpertiseSection({
  expertiseSectionTitle,
  expertiseSectionBody,
  expertiesSectionImages,
  cixBackground,
}) {
  const [ref, inView] = useInView({ triggerOnce: true })

  const images = expertiesSectionImages.map((image, i) => (
    <GatsbyImg
      sizes={image.sizes}
      alt={image.title}
      className={`image${i}`}
      key={i}
    />
  ))

  return (
    <InnerContainer ref={ref}>
      <Section
        background={'black'}
        backgroundImage={cixBackground ? cixBackground : null}
        id="expertise-section"
      >
        <InnerContainer mobileReverse>
          <SplitSection>
            <ExpertiseImagesContainer showAnimation={inView}>
              {images}
            </ExpertiseImagesContainer>
          </SplitSection>
          <SplitSection padding={'66px 0 0 50px'}>
            <SectionTitle color={cixBackground ? 'black' : '#8fa1a8'}>
              {expertiseSectionTitle}
            </SectionTitle>
            <SectionSubTitle
              color={cixBackground ? 'black' : 'white'}
              fontSize={'28px'}
              width={'96%'}
              padding={'0 0 45px'}
              lineHeight={'1.4'}
            >
              {expertiseSectionBody.expertiseSectionBody}
            </SectionSubTitle>
            <CtaWrapper>
              {cixBackground ? (
                <TransparentCTA blackBorder maxWidth="146px" />
              ) : (
                <GradientButton
                  whiteBtn={cixBackground ? true : false}
                  gradientLeft={cixBackground ? '1px' : null}
                  gradientTop={cixBackground ? '1px' : null}
                  gradientHeight={cixBackground ? 'calc(100% - 2px)' : null}
                  gradientWidth={cixBackground ? 'calc(100% - 2px)' : null}
                />
              )}
            </CtaWrapper>
          </SplitSection>
        </InnerContainer>
      </Section>
    </InnerContainer>
  )
}
