import React, { useState, useRef, useEffect } from 'react'
import {
  Quote,
  Person,
  Title,
  QuoteContent,
  SpeechBubble,
  SpeechBubbleInner,
  SpeechBubbleContainer,
} from './Basic'

function useTimer(callback, delay) {
  const savedCallback = useRef()

  // Remember the latest function.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current()
    }
    if (delay !== null) {
      let id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}

export default function AnimatedQuotes({ quotes }) {
  const [activeIndex, setActiveIndex] = useState(0)

  function increment() {
    const divisor = 3
    setActiveIndex((activeIndex + 1) % divisor)
  }

  useTimer(increment, 3500)

  return (
    <SpeechBubbleContainer>
      {quotes.map((quote, i) => (
        <SpeechBubble key={i} className={i === activeIndex ? 'active' : ''}>
          <SpeechBubbleInner>
            {quote.logo && (
              <img src={quote.logo.file.url} alt={quote.logo.description} />
            )}
            <QuoteContent>
              <Quote>{quote.quote}</Quote>
              <Person>{quote.person}</Person>
              <Title>{quote.title}</Title>
            </QuoteContent>
          </SpeechBubbleInner>
        </SpeechBubble>
      ))}
    </SpeechBubbleContainer>
  )
}
